import React from 'react';
import PropTypes from 'prop-types';

// Styles
import styles from './MethodDetailModal.module.scss';

// Icons
import closeAccentIcon from '../../static/icons/closeAccentIcon.svg';
import plusIcon from '../../static/icons/plusIcon.svg';

// Components
import MethodLabelsList from '../MethodLabelsList';
import Heading from '../Heading';

const MethodDetailModal = (props) => {
    const { heading, description, roles, principles, measurementParameters, benefits, hasBeenSelected, onClickDelete, onClickAdd, onClickClose } = props;
    return (
        <div id={styles.methodDetailModal}>
            <div id={styles.contentContainer}>
                <button id={styles.closeButton} onClick={onClickClose}>
                    <img src={closeAccentIcon} alt="" />
                </button>
                <Heading type="primary">
                    {heading}
                </Heading>
                <p>
                    {description}
                </p>
                <Heading type="secondary">
                    Rollen
                </Heading>
                <MethodLabelsList labels={roles} />
                <Heading type="secondary">
                    Prinzipien
                </Heading>
                <MethodLabelsList labels={principles} />
                <Heading type="secondary">
                    Messgrößen
                </Heading>
                <MethodLabelsList labels={measurementParameters} />
                <Heading type="secondary">
                    Benefits
                </Heading>
                <MethodLabelsList labels={benefits} />
            </div>
            <button id={styles.mainActionButton} onClick={hasBeenSelected ? onClickDelete : onClickAdd}>
                <img src={plusIcon} alt="" className={hasBeenSelected ? styles.rotated : ""} />
                <p>
                    {hasBeenSelected ? "Entfernen" : "Hinzufügen"}
                </p>
            </button>
        </div>
    )
}

MethodDetailModal.propTypes = {
    heading: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    roles: PropTypes.arrayOf(PropTypes.string).isRequired,
    principles: PropTypes.arrayOf(PropTypes.string).isRequired,
    measurementParameters: PropTypes.arrayOf(PropTypes.string).isRequired,
    benefits: PropTypes.arrayOf(PropTypes.string).isRequired,
    hasBeenSelected: PropTypes.bool.isRequired,
    onClickDelete: PropTypes.func.isRequired,
    onClickClose: PropTypes.func.isRequired,

    onClickAdd: PropTypes.func
}

MethodDetailModal.defaultProps = {
    onClickAdd: () => console.warn("No function to add the method has been defined")
}

export default MethodDetailModal;