import { parseObjectToQueryString } from './parser';

const defaultSuccessCallback = (e) => console.log("Successfully made http request", e);
const defaultErrorCallback = (e) => console.log("An error occurred trying to make the http request", e);

export const get = (url, data, requestHeaderOptions, successCallback = defaultSuccessCallback, errorCallback = defaultErrorCallback) => {
    if (data) {
        const queryString = parseObjectToQueryString(data);
        url += queryString;
    }

    const xmlHttpRequest = new XMLHttpRequest();
    xmlHttpRequest.responseType = "json";

    xmlHttpRequest.addEventListener("load", (e) => {
        const { status, statusText, response } = e.target;
        console.info(status, statusText);
        successCallback(response);
    });

    xmlHttpRequest.addEventListener("error", (e) => {
        const { status, statusText, response } = e.target;
        console.warn(status, statusText);
        errorCallback(response);
    });

    xmlHttpRequest.open("GET", url, true);

    if (requestHeaderOptions) setRequestHeader(xmlHttpRequest, requestHeaderOptions);

    xmlHttpRequest.send();
}

const setRequestHeader = (xmlHttpRequest, requestHeaderOptions) => {
    for (const key in requestHeaderOptions) {
        xmlHttpRequest.setRequestHeader(key, requestHeaderOptions[key]);
    }
}