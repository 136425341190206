import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

// Styles
import styles from './ValueSuggestionsList.module.scss';

// Components
import ValueSuggestion from '../ValueSuggestion';

const ValueSuggestionsList = (props) => {
    const { heading, valueSuggestions, onClickValueSuggestion } = props;

    return (
        <Fragment>
            {valueSuggestions.length > 0 &&
                <div className={styles.valueSuggestionsList}>
                    <p>
                        {heading}
                    </p>
                    <ul>
                        {valueSuggestions.map((valueSuggestion, index) => (
                            <li key={index} className={styles.valueSuggestionContainer}>
                                <ValueSuggestion
                                    title={valueSuggestion.fields["1_Name Technik DE"]}
                                    onClick={() => onClickValueSuggestion(valueSuggestion)}
                                />
                            </li>
                        ))}
                    </ul>
                </div>
            }
        </Fragment>
    )
}

ValueSuggestionsList.propTypes = {
    heading: PropTypes.string.isRequired,
    valueSuggestions: PropTypes.arrayOf(PropTypes.object).isRequired,
    onClickValueSuggestion: PropTypes.func.isRequired
}

export default ValueSuggestionsList;