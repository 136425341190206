import React from 'react';
import PropTypes from 'prop-types';

// Styles
import styles from './TabSwitch.module.scss';

const TabSwitch = (props) => {
    const { isTurnedOn, onChangeTabItem } = props;
    return (
        <div id={styles.tabSwitch}>
            <button onClick={() => !isTurnedOn ? false : onChangeTabItem()} className={!isTurnedOn ? styles.active : styles.inactive}>
                Innovation
            </button>
            <button onClick={() => !isTurnedOn ? onChangeTabItem() : false} className={isTurnedOn ? styles.active : styles.inactive}>
                Umsetzung
            </button>
            <div id={styles.triangleContainer}>
                <div id={styles.triangle} className={!isTurnedOn ? styles.active : styles.inactive} />
            </div>
        </div>
    )
}

TabSwitch.propTypes = {
    isTurnedOn: PropTypes.bool.isRequired,
    onChangeTabItem: PropTypes.func.isRequired
}

export default TabSwitch;