import React, { Component, Fragment } from 'react';
import { cloneDeep } from 'lodash';

// Components
import Header from '../../components/Header';
import MethodsListsBlocksList from '../../components/MethodsListsBlocksList';
import MethodsListsBlocksListHeader from '../../components/MethodsListsBlocksListHeader';
import TabSwitch from '../../components/TabSwitch';
import MethodDetailModal from '../../components/MethodDetailModal';
import Heading from '../../components/Heading';
import MoreDetailsSidebar from '../../components/MoreDetailsSidebar';

// Styles
import styles from './MyTools.module.scss';

// Utils
import { MyContext } from '../../utils/myContext';
import * as parser from '../../utils/parser';


class MyTools extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isTabSwitchTurnedOn: false,

            shouldShowMethodDetailModal: false,

            isMoreDetailsSidebarExpanded: false,

            methodsListsStartIndex: 0,
            methodsListsEndIndex: 4,
            
            moreDetailsSidebarLabelSections: []
        };

        let clickedMethod = undefined;
        console.log(clickedMethod);    
    }

    componentDidMount = () => this.parseMoreDetailsSidebarLabelSections();

    parseMoreDetailsSidebarLabelSections = async () => {
        const moreDetailsSidebarLabelSections = await parser.parseMoreDetailsSidebarLabelSections(this.context.state.sortedSelectedMethods);
        this.setState({
            moreDetailsSidebarLabelSections
        });
    }

    render = () => {
        console.log("MyTools is (re)rendering")
        return (
            <Fragment>
                <Header
                    searchFieldValue={this.context.state.headerSearchFieldValue}
                    onChangeSearchFieldValue={this.onChangeHeaderSearchFieldValue}
                    searchFieldValueSuggestionsLists={this.context.state.headerSearchFieldValueSuggestionsLists}
                    onClickSearchFieldValueSuggestion={this.onClickHeaderSearchFieldValueSuggestion}
                    onClickSearchFieldCloseIcon={this.onClickHeaderSearchFieldCloseIcon}
                    sortedSelectedMethodsCount={this.context.state.sortedSelectedMethodsCount}
                />
                <main>
                    {this.state.shouldShowMethodDetailModal &&
                        <div id={styles.methodDetailModalOuterContainer}>
                            <div id={styles.methodDetailModalInnerContainer}>
                                <MethodDetailModal
                                    heading={this.clickedMethod.fields["1_Name Technik DE"]}
                                    description={this.clickedMethod.fields["5_Beschreibung DE"]}
                                    roles={this.clickedMethod.fields["23_Rollen DE"]}
                                    principles={this.clickedMethod.fields["35_Prinzipien DE"]}
                                    measurementParameters={this.clickedMethod.fields["27_Messgrössen konsolidiert DE"]}
                                    benefits={this.clickedMethod.fields["15_Benefits konsolidiert DE"]}
                                    hasBeenSelected={this.clickedMethod.appendix.hasBeenSelected}
                                    onClickAdd={this.onClickAddMethod}
                                    onClickDelete={this.onClickDeleteMethod}
                                    onClickClose={this.onClickCloseMethodDetailModal}
                                />
                            </div>
                        </div>
                    }
                    <div id={styles.contentContainer}>
                        <div>
                            <Heading type="primary">
                                Meine Tools
                            </Heading>
                            <p>
                                Ihre ausgewählten Methoden
                            </p>
                        </div>
                        <div>
                            <TabSwitch
                                isTurnedOn={this.state.isTabSwitchTurnedOn}
                                onChangeTabItem={this.onChangeTabItem}
                            />
                        </div>
                        <div>
                            <div id={styles.methodsListsBlocksListHeaderContainer}>
                                <MethodsListsBlocksListHeader
                                    methodsListsStartIndex={this.state.methodsListsStartIndex}
                                    methodsListsEndIndex={this.state.methodsListsEndIndex}
                                />
                            </div>
                            <div>
                                <MethodsListsBlocksList
                                    methodsListsBlocks={this.context.state.sortedSelectedMethods}
                                    methodsListsStartIndex={this.state.methodsListsStartIndex}
                                    methodsListsEndIndex={this.state.methodsListsEndIndex}
                                    onClickMethod={this.onClickMethod}
                                    shouldSelectedMethodsBeHighlighted={false}
                                />
                            </div>
                        </div>
                    </div>
                    <MoreDetailsSidebar
                        isExpanded={this.state.isMoreDetailsSidebarExpanded}
                        labelSections={this.state.moreDetailsSidebarLabelSections}
                        onClickInformationIcon={this.toggleMoreDetailsSidebarWidth}
                        onClickReturnButton={this.toggleMoreDetailsSidebarWidth}
                    />
                </main>
            </Fragment>
        )
    }

    onChangeHeaderSearchFieldValue = (e) => {
        const headerSearchFieldValue = e.target.value;
        this.context.setState({
            headerSearchFieldValue
        }, async () => {
            const headerSearchFieldValueSuggestionsLists = await parser.getHeaderSearchFieldValueSuggestionsLists(headerSearchFieldValue, this.context.state.sortedMethods);
            this.context.setState({
                headerSearchFieldValueSuggestionsLists
            })
        });
    };

    onClickHeaderSearchFieldValueSuggestion = (headerSearchFieldValueSuggestion) => {
        this.clickedMethod = headerSearchFieldValueSuggestion;

        this.setState({
            shouldShowMethodDetailModal: true
        })
    };

    onClickHeaderSearchFieldCloseIcon = () => this.context.setState({
        headerSearchFieldValue: ""
    });

    onClickMethod = (method) => {
        this.clickedMethod = method;

        this.setState({
            shouldShowMethodDetailModal: true
        });
    }

    onChangeTabItem = () => {
        this.setState({
            isTabSwitchTurnedOn: !this.state.isTabSwitchTurnedOn,

            methodsListsStartIndex: !this.state.isTabSwitchTurnedOn ? 4 : 0,
            methodsListsEndIndex: !this.state.isTabSwitchTurnedOn ? 8 : 4
        });
    }

    onClickAddMethod = () => {
        const { clickedMethod } = this;
        const { collaborationLayerIndex, phaseDeepDiveIndex, methodIndex } = clickedMethod.appendix;

        const deepClonedSortedMethods = cloneDeep(this.context.state.sortedMethods);
        const affectedMethod = deepClonedSortedMethods[collaborationLayerIndex][phaseDeepDiveIndex][methodIndex];
        affectedMethod.appendix.hasBeenSelected = true;

        const deepClonedSortedSelectedMethods = cloneDeep(this.context.state.sortedSelectedMethods);
        deepClonedSortedSelectedMethods[collaborationLayerIndex][phaseDeepDiveIndex].push(affectedMethod);

        this.context.setState({
            sortedMethods: deepClonedSortedMethods,
            sortedSelectedMethods: deepClonedSortedSelectedMethods,
            sortedSelectedMethodsCount: this.context.state.sortedSelectedMethodsCount + 1
        });

        this.setState({
            shouldShowMethodDetailModal: false,
        });
    };

    onClickDeleteMethod = () => {
        const { clickedMethod } = this;
        const { collaborationLayerIndex, phaseDeepDiveIndex, methodIndex } = clickedMethod.appendix;

        const deepClonedSortedMethods = cloneDeep(this.context.state.sortedMethods);
        const affectedMethod = deepClonedSortedMethods[collaborationLayerIndex][phaseDeepDiveIndex][methodIndex];
        affectedMethod.appendix.hasBeenSelected = false;

        let deepClonedSortedSelectedMethods = cloneDeep(this.context.state.sortedSelectedMethods);
        deepClonedSortedSelectedMethods[collaborationLayerIndex][phaseDeepDiveIndex] = deepClonedSortedSelectedMethods[collaborationLayerIndex][phaseDeepDiveIndex].filter(method => method.id !== clickedMethod.id);

        this.context.setState({
            sortedMethods: deepClonedSortedMethods,
            sortedSelectedMethods: deepClonedSortedSelectedMethods,
            sortedSelectedMethodsCount: this.context.state.sortedSelectedMethodsCount - 1
        }, this.parseMoreDetailsSidebarLabelSections)

        this.setState({
            shouldShowMethodDetailModal: false
        });
    };

    onClickCloseMethodDetailModal = () => this.setState({
        shouldShowMethodDetailModal: false
    });

    toggleMoreDetailsSidebarWidth = () => this.setState({
        isMoreDetailsSidebarExpanded: !this.state.isMoreDetailsSidebarExpanded
    })
}

MyTools.contextType = MyContext;

export default MyTools;
