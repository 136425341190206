import React, { Component } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

// Routes
import unprotectedRoutes from './unprotectedRoutes';

// Styling
import './App.module.scss';

// Utils
import * as http from './utils/http';
import * as parser from './utils/parser';
import { MyContext } from './utils/myContext';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortedMethods: [[[], [], [], [], [], [], [], []], [[], [], [], [], [], [], [], []], [[], [], [], [], [], [], [], []], [[], [], [], [], [], [], [], []]],
      sortedSelectedMethods: [[[], [], [], [], [], [], [], []], [[], [], [], [], [], [], [], []], [[], [], [], [], [], [], [], []], [[], [], [], [], [], [], [], []]],
      sortedSelectedMethodsCount: 0,

      headerSearchFieldValue: "",
      headerSearchFieldValueSuggestionsLists: [],

      hasInitiallyFetchedData: false
    }
  }

  componentDidMount = () => {
    const requestHeaderOptions = {
      Authorization: `Bearer ${process.env.REACT_APP_AIRTABLE_API_KEY}`
    }
    http.get("https://api.airtable.com/v0/appYMoKIKwQ8sF6M2/Agile%20Toolbox%20Database", null, requestHeaderOptions, async data => {
      const sortedMethods = await parser.getSortedMethods(data.records);
      this.setState({
        sortedMethods,
        hasInitiallyFetchedData: true
      });
    });
  }

  render() {
    return (
      <MyContext.Provider value={{
        state: this.state,
        setState: (newState, callback) => this.setState(newState, callback)
      }}>
        <Router>
          <Switch>
            {unprotectedRoutes.map((unprotectedRoute, index) => {
              const { path, exact, Component } = unprotectedRoute;
              return (
                <Route
                  key={index}
                  path={path}
                  exact={exact}
                >
                  <Component />
                </Route>
              )
            })}
          </Switch>
        </Router>
      </MyContext.Provider>
    );
  };
}

export default App;
