import React from 'react';
import PropTypes from 'prop-types';

// Components
import FooterMethod from '../FooterMethod';

// Styles
import styles from './FooterMethodsList.module.scss';

const FooterMethodsList = (props) => {
    const { methods, onClickFooterMethod, onClickDeleteFooterMethod } = props;

    return (
        <ul className={styles.footerMethodsList}>
            {methods.map((method, index) => (
                <li key={index}>
                    <FooterMethod
                        title={method.fields["1_Name Technik DE"]}
                        onClick={() => onClickFooterMethod(method)}
                        onClickDelete={() => onClickDeleteFooterMethod(method)}
                        borderColor={method.appendix.borderColor}
                    />
                </li>
            ))}
        </ul>
    )
}

FooterMethodsList.propTypes = {
    methods: PropTypes.arrayOf(PropTypes.object).isRequired,
    onClickFooterMethod: PropTypes.func.isRequired,
    onClickDeleteFooterMethod: PropTypes.func.isRequired,
}

export default FooterMethodsList;