import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

// Styles
import styles from './Header.module.scss';

// Icons
import burgerMenuIcon from '../../static/icons/burgerMenuIcon.svg';
import blackFourSquaresIcon from '../../static/icons/blackFourSquaresIcon.svg';
import blackSettingsIcon from '../../static/icons/blackSettingsIcon.svg';
import grayFourSquaresIcon from '../../static/icons/grayFourSquaresIcon.svg';
import graySettingsIcon from '../../static/icons/graySettingsIcon.svg';

// Components
import SearchField from '../SearchField';

const Header = (props) => {
    const { match, searchFieldValue, onChangeSearchFieldValue, searchFieldValueSuggestionsLists, onClickSearchFieldValueSuggestion, onClickSearchFieldCloseIcon, sortedSelectedMethodsCount } = props;
    return (
        <header>
            <input id={styles.menuToggle} type="checkbox" />
            <label id={styles.mobileBurgerButton} htmlFor={styles.menuToggle}>
                <img id={styles.mobileBurgerMenuIcon} src={burgerMenuIcon} alt="" />
            </label>
            <nav>
                <ul>
                    <li>
                        <Link to="/">
                            <div className={`${styles.navItemContainer} ${match.path === "/" ? styles.matchesPath : ""}`}>
                                <img src={match.path === "/" ? blackSettingsIcon : graySettingsIcon} alt="" />
                                <p>
                                    Agile Toolbox
                                </p>
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to="/my-tools">
                            <div className={`${styles.navItemContainer} ${match.path === "/my-tools" ? styles.matchesPath : ""}`}>
                                <img src={match.path === "/" ? blackFourSquaresIcon : grayFourSquaresIcon} alt="" />
                                <p>
                                    Meine Tools
                                </p>
                                {sortedSelectedMethodsCount > 0 &&
                                    <div id={styles.sortedSelectedMethodsCount}>
                                        {sortedSelectedMethodsCount}
                                    </div>
                                }
                            </div>
                        </Link>
                    </li>
                </ul>
            </nav>
            <div id={styles.searchFieldContainer}>
                <SearchField
                    value={searchFieldValue}
                    onChangeValue={onChangeSearchFieldValue}
                    valueSuggestionsLists={searchFieldValueSuggestionsLists}
                    onClickValueSuggestion={onClickSearchFieldValueSuggestion}
                    onClickCloseIcon={onClickSearchFieldCloseIcon}
                />
            </div>
        </header>
    );
};

Header.propTypes = {
    searchFieldValue: PropTypes.string.isRequired,
    onChangeSearchFieldValue: PropTypes.func.isRequired,
    searchFieldValueSuggestionsLists: PropTypes.arrayOf(PropTypes.object).isRequired,
    onClickSearchFieldValueSuggestion: PropTypes.func.isRequired,
    onClickSearchFieldCloseIcon: PropTypes.func.isRequired,
    sortedSelectedMethodsCount: PropTypes.number.isRequired
}

export default withRouter(Header);
