import React from 'react';
import PropTypes from 'prop-types';

// Styles
import styles from './MethodLabel.module.scss';

const MethodLabel = ({ content }) => (
        <div className={styles.methodLabel}>
            <p>
                {content}
            </p>
        </div>
    )

MethodLabel.propTypes = {
    content: PropTypes.string.isRequired
}

export default MethodLabel;