import React from 'react';
import PropTypes from 'prop-types';

// Images
import expandArrowIcon from '../../static/icons/expandArrowIcon.svg';
import collapseArrowIcon from '../../static/icons/collapseArrowIcon.svg';

// Styles
import styles from './Footer.module.scss';

// Components
import FooterMethodsList from '../FooterMethodsList';
import MyToolsFooterButton from '../MyToolsFooterButton';

const phaseDeepDive = ["Wahrnehmung Chancen/Risiken", "Ideengenerierung", "Konzepterstellung", "Potentialanalyse", "Planung", "Durchführung", "Controlling", "Abschluss oder nächste Iteration"];

const Footer = (props) => {
    const { isExpanded, methodsLists, methodsListsStartIndex, methodsListsEndIndex, onClickFooterMethod, onClickDeleteFooterMethod, onClickArrowIcon } = props;

    return (
        <footer className={isExpanded ? styles.isExpanded : ""}>
            <button onClick={onClickArrowIcon}>
                <img src={!isExpanded ? expandArrowIcon : collapseArrowIcon} alt="" />
            </button>
            {!isExpanded &&
                <div id={styles.line} />
            }
            {isExpanded &&
                <div id={styles.footerMethodsListsOuterContainer}>
                    <div id={styles.footerMethodsListsInnerContainer}>
                        {methodsLists.map((methodsList, methodsListIndex) => {
                            if (!(methodsListsStartIndex <= methodsListIndex && methodsListIndex < methodsListsEndIndex)) return false;
                            return (
                                <div key={methodsListIndex} className={styles.footerMethodsListContent}>
                                    <p>
                                        {phaseDeepDive[methodsListIndex]}
                                    </p>
                                    <div className={styles.footerMethodsListContainer} key={methodsListIndex}>
                                        <FooterMethodsList
                                            methods={methodsList.reverse()}
                                            onClickFooterMethod={onClickFooterMethod}
                                            onClickDeleteFooterMethod={onClickDeleteFooterMethod}
                                        />
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div id={styles.myToolsFooterButtonContainer}>
                        <MyToolsFooterButton />
                    </div>
                </div>
            }
        </footer>
    )
}

Footer.propTypes = {
    isExpanded: PropTypes.bool.isRequired,
    methodsLists: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)).isRequired,
    methodsListsStartIndex: PropTypes.number.isRequired,
    methodsListsEndIndex: PropTypes.number.isRequired,
    onClickDeleteFooterMethod: PropTypes.func.isRequired,
    onClickArrowIcon: PropTypes.func.isRequired,

    onClickFooterMethod: PropTypes.func
}

Footer.defaultProps = {
    onClickFooterMethod: (footerMethod) => console.warn("No onClick method defined")
}

export default Footer;