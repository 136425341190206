import React from 'react';
import PropTypes from 'prop-types';

// Styles
import styles from './Heading.module.scss';

const Heading = (props) => {
    const { type, children } = props;

    switch(type) {
        case "primary":
            return (
                <h1 className={`${styles.heading} ${styles[type]}`}>
                    {children}
                </h1>
            )
        case "secondary":
            return (
                <h2 className={`${styles.heading} ${styles[type]}`}>
                    {children}
                </h2>
            )
        case "tertiary":
            return (
                <h3 className={`${styles.heading} ${styles[type]}`}>
                    {children}
                </h3>
            )
        case "quatenary":
            return (
                <h4 className={`${styles.heading} ${styles[type]}`}>
                    {children}
                </h4>
            )
        case "quintary":
            return (
                <h5 className={`${styles.heading} ${styles[type]}`}>
                    {children}
                </h5>
            )
        case "senary":
            return (
                <h6 className={`${styles.heading} ${styles[type]}`}>
                    {children}
                </h6>
            )
        default:
            return 
    }
}

Heading.propTypes = {
    type: PropTypes.string.isRequired,
    children: PropTypes.any.isRequired
}

export default Heading;