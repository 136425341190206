import React from 'react';
import PropTypes from 'prop-types';

// Icons
import arrowRightIcon from '../../static/icons/arrowRightIcon.svg';

// Styles
import styles from './ValueSuggestion.module.scss';

const ValueSuggestion = props => {
    const { title, onClick } = props;
    return (
        <div className={styles.valueSuggestion} onClick={onClick}>
            <p>
                {title}
            </p>
            <img src={arrowRightIcon} alt="" />
        </div>
    );
};

ValueSuggestion.propTypes = {
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
};

export default ValueSuggestion;