import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

// Components
import MethodsListsBlock from '../MethodsListsBlock';
import Divider from '../Divider';

// Styles
import styles from './MethodsListsBlocksList.module.scss';

const collaborationLayers = [{
    name: "Team",
    color: "darkGrenn"
}, {
    name: "Inter-Team",
    color: "lightGreen"
}, {
    name: "Kunde/Nutzer",
    color: "orange"
}, {
    name: "Inter-Organisational",
    color: "yellow"
}];

const MethodsListsBlocksList = (props) => {
    const { methodsListsBlocks, methodsListsStartIndex, methodsListsEndIndex, onClickMethod, shouldSelectedMethodsBeHighlighted } = props;

    return (
        <div className={styles.methodsListsBlocksList}>
            {methodsListsBlocks.map((methodsListsBlock, methodsListsBlockIndex) => (
                <Fragment key={methodsListsBlockIndex}>
                    <div key={methodsListsBlockIndex} className={styles.methodsListsBlocksListContent}>
                        <div className={styles.collaborationLayerContainer}>
                            <p className={styles[collaborationLayers[methodsListsBlockIndex].color]} dangerouslySetInnerHTML={{ __html: collaborationLayers[methodsListsBlockIndex].name }} />
                        </div>
                        <div className={styles.methodsListsBlockContainer}>
                            <MethodsListsBlock
                                methodsLists={methodsListsBlock}
                                methodsListsStartIndex={methodsListsStartIndex}
                                methodsListsEndIndex={methodsListsEndIndex}
                                onClickMethod={onClickMethod}
                                shouldSelectedMethodsBeHighlighted={shouldSelectedMethodsBeHighlighted}
                            />
                        </div>
                    </div>
                    {methodsListsBlockIndex < methodsListsBlocks.length - 1 &&
                        <Divider style={{ margin: ".25rem 0" }} />
                    }
                </Fragment>
            ))}
        </div>
    )
}

MethodsListsBlocksList.propTypes = {
    methodsListsBlocks: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object))).isRequired,
    methodsListsStartIndex: PropTypes.number.isRequired,
    methodsListsEndIndex: PropTypes.number.isRequired,
    onClickMethod: PropTypes.func.isRequired,
    shouldSelectedMethodsBeHighlighted: PropTypes.bool
}

MethodsListsBlocksList.defaultProps = {
    shouldSelectedMethodsBeHighlighted: true
}

export default MethodsListsBlocksList;