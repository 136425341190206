import React from 'react';
import PropTypes from 'prop-types';

// Styles
import styles from './MethodLabelsList.module.scss';

// Components
import MethodLabel from '../MethodLabel';

const MethodLabelsList = ({ labels }) => {
    return (
        <ul className={styles.methodLabelsList}>
            {labels.map((label, index) => (
                <li key={index}>
                    <MethodLabel
                        content={label}
                    />
                </li>
            ))}
        </ul>
    )
}

MethodLabelsList.propTypes = {
    labels: PropTypes.arrayOf(PropTypes.string).isRequired
}

export default MethodLabelsList;