import React from 'react';
import PropTypes from 'prop-types';

// Styles
import styles from './Divider.module.scss';

const Divider = ({ style }) => <div className={styles.divider} style={style} />

Divider.propTypes = {
    style: PropTypes.object
}

Divider.defaultProps = {
    style: {}
}

export default Divider;