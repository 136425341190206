import React from 'react';
import PropTypes from 'prop-types';

// Styles
import styles from './MethodsListsBlocksListHeader.module.scss';

const headerItems = ["Wahrnehmung Chancen/Risiken", "Ideengenerierung", "Konzepterstellung", "Potentialanalyse", "Planung", "Durchführung", "Controlling", "Abschluss oder nächste Iteration"];

const MethodsListsBlocksListHeader = (props) => {
    const { methodsListsStartIndex, methodsListsEndIndex } = props;

    return (
        <ul id={styles.methodsListsBlocksListHeader}>
            {headerItems.map((headerItem, headerItemIndex) => {
                if (!(methodsListsStartIndex <= headerItemIndex && headerItemIndex < methodsListsEndIndex)) return false;
                return (
                    <li key={headerItemIndex}>
                        {headerItem}
                    </li>
                )
            })}
        </ul>
    )
}

MethodsListsBlocksListHeader.propTypes = {
    methodsListsStartIndex: PropTypes.number.isRequired,
    methodsListsEndIndex: PropTypes.number.isRequired
}

export default MethodsListsBlocksListHeader;