import React from 'react';
import PropTypes from 'prop-types';

// Components
import MethodsList from '../MethodsList';

// Styles
import styles from './MethodsListsBlock.module.scss';

const MethodsListsBlock = (props) => {
    const { methodsLists, methodsListsStartIndex, methodsListsEndIndex, onClickMethod, shouldSelectedMethodsBeHighlighted } = props;
    return (
        <div className={styles.methodsListsContainer}>
            {methodsLists.map((methodsList, methodsListIndex) => {
                if (!(methodsListsStartIndex <= methodsListIndex && methodsListIndex < methodsListsEndIndex)) return false;
                return (
                    <div key={methodsListIndex} className={styles.methodsListContainer}>
                        <MethodsList
                            methods={methodsList}
                            onClickMethod={onClickMethod}
                            shouldSelectedMethodsBeHighlighted={shouldSelectedMethodsBeHighlighted}
                        />
                    </div>
                )
            })}
        </div>
    )
}

MethodsListsBlock.propTypes = {
    methodsLists: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)).isRequired,
    methodsListsStartIndex: PropTypes.number.isRequired,
    methodsListsEndIndex: PropTypes.number.isRequired,
    onClickMethod: PropTypes.func.isRequired,
    shouldSelectedMethodsBeHighlighted: PropTypes.bool.isRequired
}

export default MethodsListsBlock;