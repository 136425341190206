import React from 'react';
import PropTypes from 'prop-types';

// Components
import Sidebar from '../Sidebar';
import Heading from '../Heading';
import CheckboxList from '../CheckboxList';

// Styles
import styles from './FiltersSidebar.module.scss';

// Icons
import funnelIcon from '../../static/icons/funnelIcon.svg';

const FiltersSidebar = props => {
    const { isExpanded, filterSections, onChangeCheckbox, onClickInformationIcon, onClickReturnButton } = props;
    return (
        <Sidebar isExpanded={isExpanded} iconSrc={funnelIcon} heading="Filters" onClickInformationIcon={onClickInformationIcon} onClickReturnButton={onClickReturnButton}>
            {filterSections.map(({ name, heading, description, checkboxes }, filterSectionIndex) => (
                <section key={filterSectionIndex} className={styles.filterSection}>
                    <Heading type="secondary">
                        {heading}
                    </Heading>
                    <p>
                        {description}
                    </p>
                    <CheckboxList
                        items={checkboxes}
                        onChangeCheckbox={(value, isChecked) => onChangeCheckbox(name, value, isChecked)}
                    />
                </section>
            ))}
        </Sidebar>
    );
};

FiltersSidebar.propTypes = {
    isExpanded: PropTypes.bool.isRequired,
    filterSections: PropTypes.arrayOf(PropTypes.exact({
        name: PropTypes.string,
        heading: PropTypes.string,
        description: PropTypes.string,
        checkboxes: PropTypes.arrayOf(PropTypes.exact({
            label: PropTypes.string,
            value: PropTypes.string,
            isChecked: PropTypes.bool
        }))
    })).isRequired,
    onChangeCheckbox: PropTypes.func.isRequired,
    onClickInformationIcon: PropTypes.func.isRequired,
    onClickReturnButton: PropTypes.func.isRequired,
};

export default FiltersSidebar;