import React from 'react';
import { Link } from 'react-router-dom';

// Icons
import whiteFourSquaresIcon from '../../static/icons/whiteFourSquaresIcon.svg';

// Styles
import styles from './MyToolsFooterButton.module.scss';

const MyToolsFooterButton = props => {
    return (
        <Link to="/my-tools" id={styles.myToolsFooterButton}>
            <img src={whiteFourSquaresIcon} alt="" />
            <p>
                Meine Tools
            </p>
        </Link>
    );
};

export default MyToolsFooterButton;