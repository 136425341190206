import React from 'react';
import PropTypes from 'prop-types';

// Styles
import styles from './Sidebar.module.scss';

// Icons
import closeIcon from '../../static/icons/closeIcon.svg';

const Sidebar = (props) => {
    const { isExpanded, iconSrc, heading, children, onClickInformationIcon, onClickReturnButton } = props;
    return (
        <aside className={isExpanded ? styles.expanded : styles.collapsed}>
            {!isExpanded &&
                <button id={styles.informationIconButton} onClick={onClickInformationIcon}>
                    <img src={iconSrc} alt="" />
                </button>
            }
            {isExpanded &&
                <div id={styles.content}>
                    <div id={styles.topRow}>
                        <p>
                            {heading}
                        </p>
                        <button id={styles.informationIconButton} onClick={onClickInformationIcon}>
                            <img src={iconSrc} alt="" />
                        </button>
                    </div>
                    <button id={styles.desktopReturnButton} onClick={onClickReturnButton}>
                        <img id={styles.desktopCloseIcon} src={closeIcon} alt="" />
                    </button>
                    <button id={styles.mobileReturnButton} onClick={onClickReturnButton}>
                        <span id={styles.mobile}>
                            Zurück
                        </span>
                    </button>
                    <div>
                        {children}
                    </div>
                </div>
            }
        </aside>
    )
}

Sidebar.propTypes = {
    isExpanded: PropTypes.bool.isRequired,
    iconSrc: PropTypes.string.isRequired,
    heading: PropTypes.string.isRequired,
    onClickInformationIcon: PropTypes.func.isRequired,
    onClickReturnButton: PropTypes.func.isRequired
}


export default Sidebar;