// Views
import LandingPage from './views/LandingPage';
import MyTools from './views/MyTools';

const routes = [{
    path: "/",
    Component: LandingPage,
    exact: true
}, {
    path: "/my-tools",
    Component: MyTools,
    exact: true
}];

export default routes;