import React from 'react';
import PropTypes from 'prop-types';

// Icons
import closeIcon from '../../static/icons/closeIcon.svg';
import checkmarkIcon from '../../static/icons/checkmarkIcon.svg';

// Styles
import styles from './FooterMethod.module.scss';

const FooterMethod = (props) => {
    const { title, onClick, onClickDelete, borderColor } = props;

    return (
        <div className={`${styles.footerMethod} ${styles[borderColor]}`} onClick={onClick}>
            <p>
                {title}
            </p>
            <button onClick={(e) => {
                e.stopPropagation();
                onClickDelete();
            }}>
                <img src={closeIcon} alt="" />
            </button>
            <div className={styles.checkmarkIconContainer}>
                <img className={styles.checkmarkIcon} src={checkmarkIcon} alt="" />
            </div>
        </div>
    )
}

FooterMethod.propTypes = {
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    onClickDelete: PropTypes.func.isRequired,
    borderColor: PropTypes.string
}

FooterMethod.defaultProps = {
    borderColor: "gray"
}

export default FooterMethod;