import React from 'react';
import PropTypes from 'prop-types';

// Styles
import styles from './MoreDetailsSidebar.module.scss';

// Components
import Heading from '../Heading';
import MethodLabelsList from '../MethodLabelsList';
import Sidebar from '../Sidebar';

// Icons
import informationIcon from '../../static/icons/informationIcon.svg';

const MoreDetailsSidebar = (props) => {
    const { isExpanded, labelSections, onClickInformationIcon, onClickReturnButton } = props;
    return (
        <Sidebar isExpanded={isExpanded} iconSrc={informationIcon} heading="Mehr Informationen" onClickInformationIcon={onClickInformationIcon} onClickReturnButton={onClickReturnButton}>
            {labelSections.map(({ heading, description, labels }, labelSectionIndex) => (
                <section key={labelSectionIndex} className={styles.labelSection}>
                    <Heading type="secondary">
                        {heading}
                    </Heading>
                    <p>
                        {description}
                    </p>
                    <MethodLabelsList labels={labels} />
                </section>
            ))}
        </Sidebar>
    )
}

MoreDetailsSidebar.propTypes = {
    isExpanded: PropTypes.bool.isRequired,
    labelSections: PropTypes.arrayOf(PropTypes.exact({
        heading: PropTypes.string,
        description: PropTypes.string,
        labels: PropTypes.arrayOf(PropTypes.string)
    })).isRequired,
    onClickInformationIcon: PropTypes.func.isRequired,
    onClickReturnButton: PropTypes.func.isRequired
}


export default MoreDetailsSidebar;