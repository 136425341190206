import React from 'react';
import PropTypes from 'prop-types';

// Icons
import showMoreIcon from '../../static/icons/showMoreIcon.svg';
import checkmarkIcon from '../../static/icons/checkmarkIcon.svg';

// Styles
import styles from './Method.module.scss';

const Method = (props) => {
    const { title, onClick, borderColor, hasBeenSelected, shouldSelectedBeHighlighted } = props;

    return (
        <div className={`${styles.method} ${hasBeenSelected && shouldSelectedBeHighlighted ? styles.hasBeenSelected : styles[borderColor]}`} onClick={onClick}>
            <p>
                {title}
            </p>
            <img src={showMoreIcon} alt="" />
            <div className={styles.checkmarkIconContainer}>
                <img className={styles.checkmarkIcon} src={checkmarkIcon} alt="" />
            </div>
        </div>
    )
}

Method.propTypes = {
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    hasBeenSelected: PropTypes.bool.isRequired,
    borderColor: PropTypes.string.isRequired,
    shouldSelectedBeHighlighted: PropTypes.bool.isRequired
}

export default Method;