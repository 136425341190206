import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

// Styles
import styles from './SearchField.module.scss';

// Components
import ValueSuggestionsList from '../ValueSuggestionsList';

// Icons
import searchIcon from '../../static/icons/searchIcon.svg';
import closeIcon from '../../static/icons/closeIcon.svg';

const SearchField = (props) => {
    const { value, onChangeValue, valueSuggestionsLists, onClickValueSuggestion, onClickCloseIcon } = props;

    return (
        <Fragment>
                <div id={styles.searchField}>
                    <div id={styles.inputContainer}>
                        <input type="text" value={value} onChange={onChangeValue} placeholder="Suchen" />
                        <img src={searchIcon} alt="" />
                        {value.length > 0 &&
                            <button onClick={onClickCloseIcon}>
                                <img src={closeIcon} alt="" />
                            </button>
                        }
                    </div>
                    {valueSuggestionsLists.length > 0 && value.length > 0 &&
                        <div id={styles.valueSuggestionsListsContainer}>
                            {valueSuggestionsLists.map((valueSuggestionsList, index) => (
                                <div key={index} className={styles.valueSuggestionsListContainer}>
                                    <ValueSuggestionsList
                                        heading={valueSuggestionsList.heading}
                                        valueSuggestions={valueSuggestionsList.valueSuggestions}
                                        onClickValueSuggestion={onClickValueSuggestion}
                                    />
                                </div>
                            ))}
                        </div>
                    }
                </div>
        </Fragment>
    )
}

SearchField.propTypes = {
    value: PropTypes.string.isRequired,
    onChangeValue: PropTypes.func.isRequired,
    valueSuggestionsLists: PropTypes.arrayOf(PropTypes.shape({
        heading: PropTypes.string,
        valueSuggestions: PropTypes.arrayOf(PropTypes.object)
    })).isRequired,
    onClickValueSuggestion: PropTypes.func.isRequired,
    onClickCloseIcon: PropTypes.func.isRequired
}

export default SearchField;