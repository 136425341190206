import React from 'react';
import PropTypes from 'prop-types';

// Components
import Method from '../Method';

// Styles
import styles from './MethodsList.module.scss';

const MethodsList = (props) => {
    const { methods, onClickMethod, shouldSelectedMethodsBeHighlighted } = props;

    return (
        <ul className={styles.methodsList}>
            {methods.map((method, methodIndex) => (
                <li key={methodIndex}>
                    <Method
                        title={method.fields["1_Name Technik DE"]}
                        onClick={() => onClickMethod(method)}
                        hasBeenSelected={method.appendix.hasBeenSelected}
                        shouldSelectedBeHighlighted={shouldSelectedMethodsBeHighlighted}
                        borderColor={method.appendix.borderColor}
                    />
                </li>
            ))}
        </ul>
    )
}

MethodsList.propTypes = {
    methods: PropTypes.arrayOf(PropTypes.object).isRequired,
    onClickMethod: PropTypes.func.isRequired,
    shouldSelectedMethodsBeHighlighted: PropTypes.bool.isRequired
}

export default MethodsList;