const borderColors = ["darkGreen", "lightGreen", "orange", "yellow"];
const methodsListsBlocksListHeaderItems = ["Wahrnehmung Chancen/Risiken", "Ideengenerierung", "Konzepterstellung", "Potentialanalyse", "Planung", "Durchführung", "Controlling", "Abschluss oder nächste Iteration"];


export const parseObjectToQueryString = (object) => {
    let queryString = "";

    const keys = Object.keys(object);
    keys.forEach((key, index) => {
        key = key.toLowerCase();
        keys[index] = replaceInvalidUrlSigns(key)
    })
    const values = Object.values(object);
    values.forEach((value, index) => {
        if (typeof value === "string") {
            value = value.toLowerCase()
            values[index] = replaceInvalidUrlSigns(value);
        }
    })

    keys.forEach((key, index) => {
        if (index === 0) {
            queryString += `?${key}=${values[index]}`
        }
        else {
            queryString += `&${key}=${values[index]}`
        }
    })

    return queryString;
}

const replaceInvalidUrlSigns = (queryString) => {
    const regExpArg = /\s+/;
    const regExp = new RegExp(regExpArg);

    let queryStringParts = queryString.split(regExp);
    queryStringParts.forEach((queryStringPart, index) => {
        if (queryStringPart === "&") {
            queryStringParts[index] = "und";
        }
    })
    queryString = queryStringParts.join("-");
    return queryString;
}

export const getSortedMethods = async (methods) => {
    let sortedMethods = [[[], [], [], [], [], [], [], []], [[], [], [], [], [], [], [], []], [[], [], [], [], [], [], [], []], [[], [], [], [], [], [], [], []]]
    methods.forEach((method, index) => {
        const collaborationLayer = method.fields["14_Collaboration Layer EN"];
        const phaseDeepDive = method.fields["12_Phase Deep Dive EN"];
        if (collaborationLayer && phaseDeepDive) {
            const collaborationLayerIndex = getCollaborationLayerIndex(collaborationLayer);
            const phaseDeepDiveIndex = getPhaseDeepDiveIndex(phaseDeepDive);
            method.appendix = {
                borderColor: borderColors[collaborationLayerIndex],
                hasBeenSelected: false,
                collaborationLayerIndex,
                phaseDeepDiveIndex,
                methodIndex: sortedMethods[collaborationLayerIndex][phaseDeepDiveIndex].length
            }
            sortedMethods[collaborationLayerIndex][phaseDeepDiveIndex].push(method);
        }
    })
    return sortedMethods;
}

const getCollaborationLayerIndex = (collaborationLayer) => {
    switch (collaborationLayer) {
        case "Team":
            return 0;
        case "Inter-Team":
            return 1;
        case "Customer / User":
            return 2;
        case "Inter-Organizational":
            return 3;
        default:
            console.info(`The collaboration layer ${collaborationLayer} is not known`);
    }
}

// TODO Currently only one phase is considered

const getPhaseDeepDiveIndex = (phaseDeepDive) => {
    switch (phaseDeepDive[0]) {
        case "Sensing Opportunities / Risks":
            return 0;
        case "Ideation":
            return 1;
        case "Concept Generation":
            return 2;
        case "Potential Evaluation":
            return 3;
        case "Planning":
            return 4;
        case "Execution":
            return 5;
        case "Check":
            return 6;
        case "Adjustment / Next Iteration":
            return 7;
        default:
            console.info(`The phaseDeepDive ${phaseDeepDive} is not known`);
    }
}

export const parseMoreDetailsSidebarLabelSections = async (sortedMethods) => {
    const moreDetailsSidebarLabelSections = [];
    
    const roleLabels = [];
    const principleLabels = [];
    const measurementParameterLabels = [];
    const benefitLabels = [];

    sortedMethods.forEach(methodsListsBlock => {
        methodsListsBlock.forEach(methodsList => {
            methodsList.forEach(method => {
                method.fields["23_Rollen DE"].forEach(roleLabel => {
                    if (!roleLabels.includes(roleLabel)) roleLabels.push(roleLabel);
                })
                method.fields["35_Prinzipien DE"].forEach(principleLabel => {
                    if (!principleLabels.includes(principleLabel)) principleLabels.push(principleLabel);
                })
                method.fields["27_Messgrössen konsolidiert DE"].forEach(measurementParameterLabel => {
                    if (!measurementParameterLabels.includes(measurementParameterLabel)) measurementParameterLabels.push(measurementParameterLabel);
                })
                method.fields["15_Benefits konsolidiert DE"].forEach(benefitLabel => {
                    if (!benefitLabels.includes(benefitLabel)) benefitLabels.push(benefitLabel);
                })
            })
        })
    })

    moreDetailsSidebarLabelSections.push({
        heading: "Rollen",
        description: "Nähere Beschreibung zu Rollen",
        labels: roleLabels
    }, {
        heading: "Prinzipien",
        description: "Nähere Beschreibung zu Prinzipien",
        labels: principleLabels
    }, {
        heading: "Prinzipien",
        description: "Nähere Beschreibung zu Prinzipien",
        labels: measurementParameterLabels
    }, {
        heading: "Benefits",
        description: "Nähere Beschreibung zu Benefits",
        labels: benefitLabels
    })

    return moreDetailsSidebarLabelSections;
}

export const parseFiltersSidebarSections = async (sortedMethods) => {
    const filtersSidebarSections = [];

    const roleCheckboxes = [];
    const principleCheckboxes = [];
    const measurementParameterCheckboxes = [];
    const benefitCheckboxes = [];

    sortedMethods.forEach(methodsListsBlock => {
        methodsListsBlock.forEach(methodsList => {
            methodsList.forEach(method => {
                method.fields["26_Rollen EN Kürzel"].forEach((value, index) => {
                    if (!roleCheckboxes.find(roleCheckbox => roleCheckbox.value === value)) {
                        const checkbox = {
                            label: method.fields["23_Rollen DE"][index],
                            value,
                            isChecked: false
                        }
                        roleCheckboxes.push(checkbox)
                    }
                })
                method.fields["38_Prinzipien EN Kürzel"].forEach((value, index) => {
                    if (!principleCheckboxes.find(principleCheckbox => principleCheckbox.value === value)) {
                        const checkbox = {
                            label: method.fields["35_Prinzipien DE"][index],
                            value,
                            isChecked: false
                        }
                        principleCheckboxes.push(checkbox)
                    }
                })
                method.fields["30_Messgrössen konsolidiert EN Kürzel"].forEach((value, index) => {
                    if (!measurementParameterCheckboxes.find(measurementParameterCheckbox => measurementParameterCheckbox.value === value)) {
                        const checkbox = {
                            label: method.fields["27_Messgrössen konsolidiert DE"][index],
                            value,
                            isChecked: false
                        }
                        measurementParameterCheckboxes.push(checkbox)
                    }
                })
                method.fields["18_Benefits konsolidiert EN Kürzel"].forEach((value, index) => {
                    if (!benefitCheckboxes.find(benefitCheckbox => benefitCheckbox.value === value)) {
                        const checkbox = {
                            label: method.fields["15_Benefits konsolidiert DE"][index],
                            value,
                            isChecked: false
                        }
                        benefitCheckboxes.push(checkbox)
                    }
                })
            })
        })
    })

    filtersSidebarSections.push({
        name: "26_Rollen EN Kürzel",
        heading: "Rollen",
        description: "Nähere Beschreibung zu Rollen",
        checkboxes: roleCheckboxes
    }, {
        name: "38_Prinzipien EN Kürzel",
        heading: "Prinzipien",
        description: "Nähere Beschreibung zu Prinzipien",
        checkboxes: principleCheckboxes
    }, {
        name: "30_Messgrössen konsolidiert EN Kürzel",
        heading: "Prinzipien",
        description: "Nähere Beschreibung zu Prinzipien",
        checkboxes: measurementParameterCheckboxes
    }, {
        name: "18_Benefits konsolidiert EN Kürzel",
        heading: "Benefits",
        description: "Nähere Beschreibung zu Benefits",
        checkboxes: benefitCheckboxes
    })

    return filtersSidebarSections;
}

export const getHeaderSearchFieldValueSuggestionsLists = async (headerSearchFieldValue, sortedMethods) => {
    let getHeaderSearchFieldValueSuggestionsLists = [];
    const headerSearchFieldValueSuggestionsListFromAllMethods = getHeaderSearchFieldValueSuggestionsListFromAllMethods(headerSearchFieldValue, sortedMethods);
    if (headerSearchFieldValueSuggestionsListFromAllMethods) getHeaderSearchFieldValueSuggestionsLists.push(headerSearchFieldValueSuggestionsListFromAllMethods);
    const headerValueSuggestionsListsFromPhaseDeepDive = getHeaderSearchFieldValueSuggestionsListsFromPhaseDeepDive(headerSearchFieldValue, sortedMethods);
    if (headerValueSuggestionsListsFromPhaseDeepDive.length > 0) getHeaderSearchFieldValueSuggestionsLists.push(...headerValueSuggestionsListsFromPhaseDeepDive);

    return getHeaderSearchFieldValueSuggestionsLists;
}

const getHeaderSearchFieldValueSuggestionsListFromAllMethods = (headerSearchFieldValue, sortedMethods) => {
    const headerSearchFieldValueSuggestionsListFromAllMethods = {
        heading: "Methoden",
        valueSuggestions: []
    };
    const regExp = new RegExp(headerSearchFieldValue, "i");

    sortedMethods.forEach(methodsListsBlock => {
        methodsListsBlock.forEach(methodsList => {
            methodsList.forEach(method => {
                if (regExp.test(method.fields["1_Name Technik DE"])) headerSearchFieldValueSuggestionsListFromAllMethods.valueSuggestions.push(method);
            })
        })
    });

    return headerSearchFieldValueSuggestionsListFromAllMethods.valueSuggestions.length > 0 ? headerSearchFieldValueSuggestionsListFromAllMethods : undefined;
}

const getHeaderSearchFieldValueSuggestionsListsFromPhaseDeepDive = (headerSearchFieldValue, sortedMethods) => {
    const matchingPhaseDeepDiveIndexes = getMatchingPhaseDeepDiveIndexes(headerSearchFieldValue);
    let headerSearchFieldValueSuggestionsListsFromPhaseDeepDive = [];

    matchingPhaseDeepDiveIndexes.forEach(phaseDeepDiveIndex => {
        const headerSearchFieldValueSuggestionsListFromPhaseDeepDive = {
            heading: `Methoden aus Phase "${methodsListsBlocksListHeaderItems[phaseDeepDiveIndex]}"`,
            valueSuggestions: []
        };
        sortedMethods.forEach(methodsListsBlock => {
            methodsListsBlock.forEach((methodsList, methodsListIndex) => {
                if (phaseDeepDiveIndex === methodsListIndex) headerSearchFieldValueSuggestionsListFromPhaseDeepDive.valueSuggestions.push(...methodsList);
            })
        })
        headerSearchFieldValueSuggestionsListsFromPhaseDeepDive.push(headerSearchFieldValueSuggestionsListFromPhaseDeepDive);
    })

    return headerSearchFieldValueSuggestionsListsFromPhaseDeepDive;
}

const getMatchingPhaseDeepDiveIndexes = (headerSearchFieldValue) => {
    let matchingPhaseDeepDiveIndexes = [];
    const regExp = new RegExp(headerSearchFieldValue, "i");
    methodsListsBlocksListHeaderItems.forEach((methodsListsBlocksListHeaderItem, index) => {
        if (regExp.test(methodsListsBlocksListHeaderItem)) matchingPhaseDeepDiveIndexes.push(index)
    })
    return matchingPhaseDeepDiveIndexes;
}

export const filterSortedMethods = (sortedMethods, filterSections) => {
    const filteredSortedMethods = [[[], [], [], [], [], [], [], []], [[], [], [], [], [], [], [], []], [[], [], [], [], [], [], [], []], [[], [], [], [], [], [], [], []]];

    sortedMethods.forEach(methodsListsBlock => {
        methodsListsBlock.forEach(methodsList => {
            methodsList.forEach(method => {
                let isMethodMatchingFilters = true;
                filterSections.forEach(({ name, checkboxes }) => {
                    if (checkboxes.filter(checkbox => checkbox.isChecked === true).length === 0) return;
                    else if (checkboxes.find(checkbox => checkbox.isChecked === true && method.fields[name].includes(checkbox.value))) return;
                    else {
                        isMethodMatchingFilters = false;
                    }
                })
                if (isMethodMatchingFilters) {
                    const collaborationLayerIndex = getCollaborationLayerIndex(method.fields["14_Collaboration Layer EN"]);
                    const phaseDeepDiveIndex = getPhaseDeepDiveIndex(method.fields["12_Phase Deep Dive EN"]);
                    filteredSortedMethods[collaborationLayerIndex][phaseDeepDiveIndex].push(method);
                }
            })
        })
    })

    return filteredSortedMethods;
}